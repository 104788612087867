<script>
import { Table, Switch, Popconfirm, Divider, Tag, Button } from 'ant-design-vue'
import PopButton from '../../components/PopButton'
import apiTool from '@/command/apiTool'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { getAction } from '@/command/netTool'
export default {
  name: 'tab-view',
  props: {
    pageParams: {
      type: Object,
      default: () => {},
    },
    url: {
      type: String,
      default: '',
    },
    rowSelection: {
      type: Object,
      default: () => {},
    },
    showRowSelect: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    styles: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onSelectRow: {
      type: Function,
      default: null,
    },
    showFootButton: {
      type: Boolean,
      default: false,
    },
    addText: {
      type: String,
      default: '+新增',
    },
    onAddData: {
      type: Function,
      default: null,
    },
    onAddCustom: {
      type: Function,
      default: null,
    },
    onTransferData: {
      type: Function,
      default: null,
    },
    isShowDelBtn: {
      type: Boolean,
      default: true,
    },
    childrenColumnName: {
      type: String,
      default: '',
    },
    header: {
      type: Function,
      default: null,
    },
    renderHeader: {
      type: Function,
      default: null,
    },
    onChange: {
      type: Function,
      default: null,
    },
    havedrag: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    scrollX: {
      type: Number | Boolean,
      default: true,
    },
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    renderFoot: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      // 选中的key
      selectedRowKeys: [],
      // 选中的对应数据
      selectedRows: [],
      locale: zhCN,
      dataSource: [],
      params: this.pageParams || {},
    }
  },
  watch: {
    selectedRowKeys: {
      handler(newValue, oldValue) {
        if (newValue instanceof Array && newValue.length) {
          this.selectedRowKeys = newValue
        }
      },
      deep: true,
      immediate: true,
    },
    pageParams: {
      handler(newValue) {
        this.getList({ ...newValue, current: 1, pageSize: 10 })
      },
    },
    dataSource: {
      handler(newValue, oldValue) {
        console.log('--------------', newValue)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(params = this.params) {
      getAction(this.url, {...params,...this.pageParams}).then((e) => {
        // console.log('this.onTransferData', this)
        this.dataSource = this.$listeners.transferData ? this.$listeners.transferData(e.data.records) : e.data.records
        this.params = e.data
        // this.$parent.current = e.data.current
        // this.$parent.total = e.data.total
        // console.log('  this.$parent', this.$parent)
      })
    },
    // 控制字体换行
    renderWrapText(text) {
      return <div class="wrap-text">{text}</div>
    },
    renderSwitch({ item, text, records }) {
      return <Switch checked={text == '0'} defaultChecked={text == '0'} onChange={(e) => item.onChange(e, records)} />
    },
    renderImage({ text = '', records, item }) {
      const data = item.getData ? item.getData(records) : (text || '').split(',')
      return data
        .filter((e) => e)
        .map((e) => {
          return (
            <img
              src={e}
              style={{
                height: '30px',
                width: '30px',
                backgroundSize: '100% 100%',
                marginRight: '10px',
                borderRadius: '4px',
              }}
            />
          )
        })
    },
    renderState({ text }) {
      //  0 启用 1 禁用
      if (text == 0) {
        return '启用'
      } else if (text == 1) {
        return '禁用'
      }
    },
    renderBadge({ text, item, records }) {
      const params = item.render ? item.render(text, records) : item
      const { showDot = true } = params
      return (
        <div class="table-badge">
          {showDot && <p style={{ background: params.color }} />}
          <span>{params.name}</span>
        </div>
      )
    },
    renderTagGroup({ item, records }) {
      const data = item.getData ? item.getData(records) : []
      return (
        <div>
          {data
            .filter((e) => e)
            .map((e) => {
              return <Tag style={{ marginTop: '10px' }}>{e}</Tag>
            })}
        </div>
      )
    },
    getButtonGroupData(item, records, index) {
      return (typeof item.typeData === 'function' ? item.typeData.call(this, { records, index }) : item.typeData) || []
    },
    stopPropagation(event) {
      event.preventDefault()
      event.stopPropagation()
      return false
    },
    renderButtonGroup({ item, records, index }) {
      //* ************************************ */
      const buttonList = this.getButtonGroupData(item, records, index)
      const buttonName = apiTool.getButtonName()
      const filterButton = buttonList.filter((el) => buttonName.some((ea) => el.name.indexOf(ea) !== -1))
      let buttonRightArr = []
      if (process.env.NODE_ENV === 'development') {
        buttonRightArr = buttonList
      } else {
        buttonRightArr = filterButton
      }
      const button = buttonRightArr.map((e, i) => {
        if (e.type == 'pop') {
          const props = {}
          if (e.onCancel) {
            props['onCancel'] = e.onCancel
          }
          return (
            <Popconfirm
              title={e.popTitle}
              icon={e.icon}
              onConfirm={e.onClick}
              cancelText={e.cancelText}
              okText={e.okText}
              placement={e.placement}
              {...props}
            >
              <a class="option-button">
                {e.name}
                {buttonList.length !== i + 1 && <Divider type="vertical" />}
              </a>
            </Popconfirm>
          )
        } else {
          return (
            <a class={['option-button']} style={{ color: e.disabled && '#A0A0A0' }}>
              <span onClick={() => e.onClick(this)}>{e.name}</span>
              {buttonList.length !== i + 1 && <Divider type="vertical" />}
            </a>
          )
        }
      })
      return (
        <div
          onClick={this.stopPropagation}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {button}
        </div>
      )
    },
    getColumnsType(item) {
      if (!item.type) return {}
      let w
      if (item.type.indexOf('-') != -1) {
        w = item.type.split('-')[1]
      }
      switch (item.type) {
        case 'index':
          return { width: 80 }
        case 'lt-' + w:
          return { width: Number(w), align: 'left' }
        case 'rt-' + w:
          return { width: Number(w), align: 'right' }
        case 'ct-' + w:
          return { width: Number(w), align: 'center' }
        default:
          return {}
      }
    },
    getEditDelete(text, records, index, item) {
      let delDisabled = false
      if (item.disabledKey) {
        delDisabled = records[item.disabledKey] > 0 ? true : false
      }
      const button = [
        {
          display: item.unShowEditBtn ? false : true,
          name: records.isEdit ? '保存' : '编辑',
          type: 'primary',
          ghost: true,
          onClick: () => {
            if (!records.isEdit && item.onEdit) {
              item.onEdit && item.onEdit(text, records, index)
            } else if (records.isEdit && item.onSave) {
              item.onSave && item.onSave(text, records, index)
            } else {
              this.$set(this.dataSource[index], 'isEdit', !records.isEdit)
            }
            this.$forceUpdate()
          },
          style: records.isEdit ? { border: '1px solid #18BE6B', color: '#18BE6B' } : {},
        },
        {
          display: this.isShowDelBtn,
          disabled: delDisabled,
          name: '删除',
          type: 'danger',
          ghost: true,
          isPop: delDisabled ? false : true,
          popTitle: '是否删除?',
          onClick: () => {
            let id = this.dataSource[index].id
            if (item.onDel) {
              item.onDel(text, records, index)
            } else {
              if (this.$listeners['delListData']) {
                this.$emit('delListData', id, index)
              } else {
                this.dataSource.splice(index, 1)
              }
            }
          },
        },
      ].filter((e) => e.display)
      return (
        <div>
          {button.map((e) => {
            return <PopButton item={e} onClick={e.onClick} />
          })}
        </div>
      )
    },
    getWidth(item) {
      if (item.width) {
        // console.log('object', item.width, item)
        if (typeof item.width == 'string') {
          if (item.width.indexOf('px') !== -1) {
            return item.width
          } else {
            return item.width + 'px'
          }
        } else {
          return item.width + 'px'
        }
      } else if (item.type && item.type.indexOf('-') !== -1) {
        return item.type.split('-')[1] + 'px'
      } else {
        return item.width
      }
    },
    renderCustom(item, view) {
      return <div style={{ minWidth: this.getWidth(item) }}>{view}</div>
    },
    getColumns(data = []) {
      let that = this
      return data.map((e) => {
        return {
          ...e,
          align: e.align || 'center',
          fixed: e.fixed || false,
          ...this.getColumnsType(e),
          customRender: function customRender(text, records, index) {
            try {
              // 判断是否是序号
              if (e.type == 'index') return index + 1
              // 判断是否是编辑与删除模式
              if (e.tableType == 'editDelete') return that.getEditDelete(text, records, index, e)
              // 判断是否有自定义视图
              if (e.customRender) {
                const custom = e.customRender(text, records, index, that.$createElement)
                // 如果有attr属性 说明是要直接返回不需要包装
                if (custom) {
                  if (custom.attrs) {
                    return custom
                  }
                  return that.renderCustom(e, custom)
                } else {
                  return null
                }
              }
              // 判断当前类型是否有值
              if (!e.type) return that.renderCustom(e, text)
              const name = e.type.slice(0, 1).toUpperCase() + e.type.slice(1)
              // 判断当前自定义函数是否存在
              if (!that['render' + name]) return that.renderCustom(e, text)
              const view = that['render' + name].call(that, {
                item: e,
                text,
                records,
                index,
              })
              return that.renderCustom(e, view)
            } catch (error) {
              // console.log('table渲染报错', e)
              return <div>{text}</div>
            }
          },
        }
      })
    },
    handleClickRow(record, index) {
      let click = () => {
        this.$listeners.selectRow && this.$listeners.selectRow(record, index)
      }
      if (!this.havedrag) {
        return {
          on: {
            click,
          },
        }
      }
      return {
        on: {
          click,
          mouseenter: (event) => {
            event.target.draggable = true
          },
          // 开始拖拽
          dragstart: (event) => {
            event.stopPropagation()

            this.sourceindex = index
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            event.preventDefault()
          },
          // 鼠标松开
          drop: (event) => {
            event.stopPropagation()

            this.targetindex = index

            this.dataSource.splice(
              this.targetindex,
              1,
              ...this.dataSource.splice(this.sourceindex, 1, this.dataSource[this.targetindex])
            )
          },
        },
      }
    },
    onRowChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.onAddCustom && this.onAddCustom(selectedRowKeys)
    },
    onAdd() {
      if (this.$listeners.handleAddData) {
        this.$listeners.handleAddData()
      } else if (this.onAddCustom) {
        this.onAddCustom()
      } else {
        this.dataSource.push({
          isEdit: true,
          ...(this.onAddData ? this.onAddData() : {}),
        })
        this.$forceUpdate()
        // this.$set(this.dataSource, this.dataSource.length, {
        //   isEdit: true,
        //   ...(this.onAddData ? this.onAddData() : {}),
        // })
      }
    },
    // renderFoot() {
    //   if (!this.showFootButton) return null
    //   return (
    //     <Button ghost={true} type={'dashed'} block={true} onClick={this.onAdd}>
    //       {this.addText}
    //     </Button>
    //   )
    // },
    getRowSelect() {
      if (!this.showRowSelect) {
        return null
      } else {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onRowChange,
          ...this.rowSelection,
        }
      }
    },
    getPagination() {
      if (this.showPagination) {
        return { showSizeChanger: true, ...this.params }
      } else {
        return false
      }
    },
    onChange(data, filter) {
      this.getList({ current: data.current, size: data.pageSize })
    },
  },
  render() {
    console.log('asdasdasdadadsadasd', this.selectedRowKeys)
    return (
      <a-config-provider locale={this.locale}>
        <Table
          style={this.styles}
          pagination={this.getPagination()}
          columns={this.getColumns(this.columns)}
          dataSource={this.dataSource}
          rowSelection={this.getRowSelect()}
          onChange={this.onChange}
          scroll={{ x: this.scrollX }}
          size={this.size}
          loading={this.loading}
          class="table-view-main"
          customRow={this.handleClickRow}
          title={this.header}
          footer={this.renderFoot}
          childrenColumnName={this.childrenColumnName}
          showHeader={this.showHeader}
          rowKey={this.rowKey}
          bordered={this.bordered}
        ></Table>
      </a-config-provider>
    )
  },
}
</script>
<style lang="less" scoped>
.table-view-main {
  background: white;
  /deep/.ant-table-footer {
    background: white;
  }
  /deep/.ant-table-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    clear: both;
    width: 100%;
  }
  /deep/thead {
    tr {
      height: 57px;
    }
  }
  /deep/.ant-table-align-left.ant-table-row-cell-break-word {
    padding-left: 15px;
  }
}
.table-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  p:first-child {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    margin-bottom: 0px;
  }
  span:last-child {
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #515a6e;
    margin-left: 10px;
  }
}
.option-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   width: 50px;

  &:nth-last-of-type(1) {
    /deep/.ant-divider.ant-divider-vertical {
      display: none;
    }
  }
}
.wrap-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

/deep/.ant-table-body {
  overflow-x: auto !important;
}
</style>
